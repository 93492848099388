import axios from 'axios';

axios
  .get('https://api.rss2json.com/v1/api.json?rss_url=https%3A%2F%2Forisen.hamazo.tv%2Findex.xml')
  .then(function(response){
    var posts = response.data.items.slice(0,4)
    getBlogPosts(posts);
  });

var blogWrapper = document.querySelector(".blog-wrapper");
const regex = /([0-9]+)\-([0-9]+)\-([0-9]+).*/;
const getBlogPosts = function(posts){
  posts.forEach(function(post){
    var postDate = post.pubDate.replace(regex, "$1/$2/$3")
    var blogItem = document.createElement("a")
    blogItem.classList.add("blog-item");
    blogItem.setAttribute("href", post.link);
    blogItem.innerHTML = `
      <div class='blog-item-thumb' alt='${post.title}' style='background-image:url(${post.enclosure.link})'></div>
      <div class='blog-item-data'>
        <p class='blog-item-title'>${post.title}</p>
        <div class='blog-item-postdate'>${postDate}</div>
      </div>`;
    blogWrapper.appendChild(blogItem);
  })
};

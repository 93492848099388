import 'normalize.css';
import './stylus/style.styl';
import './getBlogPosts.js';
import Swiper from 'swiper/swiper-bundle';
import 'swiper/swiper-bundle.css';

const swiper = new Swiper('.swiper-container', {
  loop: true,
  speed: 400,
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
  },
});
swiper.autoplay.start()
